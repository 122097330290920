<template>
    <div class="outer">
        <div class="inner">
            <div class="content">
                <div class="title">
                    <span>{{ $t('el.main.index.result.checkDetail') }}</span>
                    <span>Test result details</span>
                </div>
                <div class="desc">
                    <span>{{ $t('el.main.index.result.contractName') }}：{{ file }}</span>
                    <span>{{ $t('el.main.index.result.checkTime') }}：{{ time }}</span>
                    <span>{{ $t('el.main.index.result.friendlyTips') }}：</span>
                    <span>{{
                            isAuditTimeout ?
                                `${$t('el.main.index.result.tip1')}` :
                                `${$t('el.main.index.result.tip2')}`
                        }}
                        Email：<a href="mailto:contact@beosin.com" class="tip-concat" style="color: #6e758a">contact@beosin.com</a> ，
                        Telegram: <a href="https://t.me/beosin" class="tip-concat" style="color: #6e758a" target="_blank">https://t.me/beosin</a>，
                        Twitter: <a href="https://twitter.com/Beosin_com" class="tip-concat" style="color: #6e758a" target="_blank">https://twitter.com/Beosin_com</a> 
                    </span>
                </div>
                <div class="pic" v-if='total>0'>
                    <div class="left">
                        <div class="tit">
                            <span>{{ $t('el.main.index.result.levelStatistics') }}</span>
                            <span :class="$i18n.locale === 'en_US' ? 'span-en' : ''">{{
                                    $t('el.main.index.result.support')
                                }}</span>
                            <div class="line"></div>
                        </div>
                        <div class="circle1"></div>
                    </div>
                    <div class="right">
                        <div class="tit">
                            <span>{{ $t('el.main.index.result.resultStatistics') }}</span>
                            <span :class="$i18n.locale === 'en_US' ? 'span-en' : ''">{{
                                    $t('el.main.index.result.support')
                                }}</span>
                            <div class="line"></div>
                        </div>
                        <div class="flex">
                            <div class="picture"></div>
                            <div class="question">{{ $t('el.main.index.result.problemCount') }}</div>
                            <div class="number">{{ $t('el.main.index.result.projectNo') }}</div>
                            <div class="word">
                                <div class="descr">{{ $t('el.main.index.result.checkOption') }}</div>
                                <div class="line" v-for="(item, index) in errType" :key="index">
                                    {{ index + 1 }}.{{ item }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pic pic-error" v-if='total==0'>{{ $t('el.main.index.result.noProblem') }}</div>
                <div class="bottom" v-if='total>0'>
                    <div class="top">{{ $t('el.main.index.result.resultDesc') }}：{{ total }}
                        <div class="line"></div>
                    </div>
                    <!-- <div class="block" v-for="(item, index) in securityItem" :key="index"> -->
                    <div class="block">
                        <!-- <div class="block-tit">
                          <div class="cir"></div>
                          <div class="word">{{ item.safeAuditItem }}</div>
                        </div> -->

                        <div class="item" v-for="(v, i) in securityList" :key="i">
                            <div class="line-bottom">
                <span>
                        <mySvg
                            :icon="
                            v.type == 'INFO'
                                ? '#icon-tz'
                                : v.type == 'WARNING'
                                ? '#icon-tz2'
                                : '#icon-tz3'
                            "
                        /></span>
                        <el-tooltip class="item" effect="dark" :content="`${formatFileTooltip(v.file)} ${$t('el.main.index.result.rowCount')}:${v.line}`" placement="top">
                            <span class="ell">{{ `${formatFileTooltip(v.file)} ${$t('el.main.index.result.rowCount')} ： ${v.line}`  }}</span>
                        </el-tooltip>
                                    </div>
                            <div class="line-top">
                                {{ $i18n.locale === 'en_US' ? v.title : v.title_zh }}：{{ v.description }}
                            </div>
                            <div class="line-type">{{ $t('el.main.index.result.fixAdvice') }}：{{ $i18n.locale === 'en_US' ? (v.advice || '') : (v.advice_zh || '')}} 
                                 <template v-if="v.wiki"> 
                                    <span class="err-doc-url" @click="open(v)">{{$t('el.main.index.errDoc')}}</span>
                                 </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lines"></div>
            </div>
        </div>
    </div>
</template>
<script>
import {checkResult} from "@/api/file.js";
import mySvg from "./svg.vue";
import {getData} from '@/utils/save'
export default {
    data() {
        return {
            option: {
                tooltip: {
                    trigger: "item",
                    formatter: "{b} :({d}%)",
                },
                series: [
                    {
                        // color: ["#2898CC", "#FFB22B", "#FB404B"],
                        type: "pie",
                        radius: "50%",
                        data: [
                            {value: 40, name: this.$t('el.main.index.result.mediumRisk')},
                            {value: 735, name: this.$t('el.main.index.result.lowRisk')},
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            },
            options: {
                color: ["#3398DB"],
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        data: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
                        // data:[],
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            color: "#D3DCF5",
                            fontSize: "16",
                            fontWeight: 400,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "rgba(219,225,255,1)",
                                width: 0,
                                type: "solid"
                            }
                        }
                    }

                ],
                yAxis: [
                    {
                        type: "value",
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#D3DCF5",
                                width: 1,
                                type: "solid",
                            },
                            axisLabel: {
                                color: "#D3DCF5",
                                fontSize: "16",
                                fontWeight: 400,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#D3DCF5",
                            },
                        },
                    },
                ],
                series: [
                    {
                        type: "bar",
                        barWidth: "60%",
                        data: [0, 0, 0, 0, 5, 18, 0, 0, 0],
                        itemStyle: {
                            normal: {
                                color: "#5882FF",
                            },
                        },
                    },
                ],
            },
            //安全检测项
            securityItem: [],
            //错误类型
            errType: [],
            //total
            total: 0,
            file: "",
            time: "",
            // 安全检测项 经过排序的
            securityList: [],
            // 检测结果是否超时
            isAuditTimeout: false
        };
    },
    methods: {
        open(v) {
            const url = v.wiki
            window.open(url, 'errDetailDoc')
        },
        echartsInit() {
            let option = this.option;
            this.$echarts.init(document.querySelector(".circle1")).setOption(option);
            let options = this.options;
            this.$echarts.init(document.querySelector(".picture")).setOption(options);
        },
    },
    computed:{
        formatFileTooltip() {
            return function (str){
                return (/默认项目/.test(str) &&  this.$i18n.locale === 'en_US') ? str.replace(/默认项目/,'DemoProject') : str
            }
        },
    },
    components: {
        mySvg,
    },
    created() {
        this.errType = JSON.parse(sessionStorage.getItem("errType"));
        const id = sessionStorage.getItem("id") * 1;

        const self = this;
        checkResult({contractId: id, dateType: sessionStorage.getItem('dateType')}).then((res) => {
            if (res.code == 200) {
                const {result} = res.data;
                self.isAuditTimeout = res.data.is_audit_timeout;
                self.securityItem = result.filter((item) => item.count);
                self.file = res.data.contractName ? res.data.contractName : '/';
                self.options.xAxis[0].data = result.map((item, index) => index + 1);
                self.options.xAxis.data = result.map((item, index) =>
                    (index + 1).toString()
                );
                let arr = result.map((item) => item.count);
                self.options.series[0].data = arr;
                self.total = arr.reduce((prev, cur) => prev + cur);
                //低位，中危，高危漏洞统计
                const low = [];
                const middle = [];
                const height = [];

                self.securityItem.forEach((item) => {
                    item.resultList.forEach((v) => {
                        if (v.type === "INFO") {
                            v.typeNum = 0;
                            low.push(v);
                        } else if (v.type === "WARNING") {
                            v.typeNum = 1;
                            middle.push(v);
                        } else {
                            v.typeNum = 2;
                            height.push(v);
                        }
                        this.securityList.push(v)
                    });
                });
                this.securityList.sort(function (a, b) {
                    return b.typeNum - a.typeNum
                })

                //  color: ["#2898CC", "#FFB22B", "#FB404B"],
                self.option.series[0].data = [
                    {
                        value: low.length,
                        name: this.$t('el.main.index.result.lowRisk'),
                        itemStyle: {color: "#2898CC"},
                    },
                    {
                        value: middle.length,
                        name: this.$t('el.main.index.result.mediumRisk'),
                        itemStyle: {color: "#FFB22B"},
                    },
                    {
                        value: height.length,
                        name: this.$t('el.main.index.result.highRisk'),
                        itemStyle: {color: "#FB404B"},
                    },
                ].filter((item) => item.value > 0);
                //检测时间
                this.time = JSON.parse(sessionStorage.getItem("row")).startTime;
            }
        });
    },
    updated() {
        if (this.total > 0) {
            this.echartsInit();
        }

    }
};
</script>
<style scoped lang='scss'>
.ell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.err-doc-url{
    &:hover{
        text-decoration: underline;
    }
    cursor: pointer;
    color: #4084ff;
}
.tip-concat{
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
}
.outer {
    width: auto;
    background: #141620;
    height: 100vh;
    min-width: 1903px;
}

.inner {
    width: auto;
    background: #141620;
    // overflow: hidden;

    .content {
        width: 95.8%;
        margin: auto;
        background-color: #141620;
        box-sizing: border-box;
        // overflow: hidden;
        .title {
            font-size: 18px;
            padding-top: 34px;
            font-weight: bold;
            line-height: 24px;
            color: #ffffff;

            span:last-child {
                font-weight: 400;
                font-size: 14px;
                padding-left: 10px;
            }
        }

        .desc {
            padding: 5px 0 15px 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #6e758a;

            span:nth-child(2) {
                margin-left: 24px;
            }

            span:nth-child(3) {
                margin-left: 24px;
                color: #ffe058;
            }

            span:nth-child(4) {
                margin-left: 10px;
            }
        }

        .pic {
            display: flex;
            justify-content: space-between;

            &.pic-error {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                color: #6e758a;
            }

            .left {
                width: 580px;
                height: 486px;
                background: #343c4f;
                opacity: 1;
                border-radius: 6px;

                .tit {
                    height: 50px;
                    width: 580px;
                    position: absolute;

                    .line {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 1px;
                        background-image: linear-gradient(to right, #535c77 0%, #535c77 50%, transparent 0%);
                        background-size: 10px 1px;
                        background-repeat: repeat-x;
                        width: 100%;
                    }

                    line-height: 50px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #ffffff;

                    span:nth-child(1) {
                        padding-left: 30px;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        color: #6e758a;
                        padding-left: 24px;
                    }

                    span.span-en {
                        font-size: 12px;
                        padding-left: 12px;
                    }
                }

                .circle1 {
                    width: 450px;
                    height: 450px;
                    padding: 41px 0 0 43px;
                    margin-left: 0;
                }
            }

            .right {
                width: 1238px;
                height: 486px;
                background: #343c4f;
                opacity: 1;
                border-radius: 6px;

                .tit {
                    height: 50px;
                    // border-bottom: 1px dashed #535c77;
                    line-height: 50px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 50px;
                    color: #ffffff;
                    width: 1238px;
                    position: relative;

                    .line {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 1px;
                        background-image: linear-gradient(to right, #535c77 0%, #535c77 50%, transparent 0%);
                        background-size: 10px 1px;
                        background-repeat: repeat-x;
                        width: 100%;
                    }

                    span:nth-child(1) {
                        padding-left: 30px;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19px;
                        color: #6e758a;
                        padding-left: 24px;
                    }
                    span.span-en {
                        font-size: 12px;
                        padding-left: 12px;
                    }
                }

                .flex {
                    display: flex;
                    position: relative;

                    .word {
                        flex: 1;
                        //  background-color: yellow;
                        padding: 0 0 0 70px;
                        box-sizing: border-box;

                        .descr {
                            padding-top: 78px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #ffffff;
                            padding-bottom: 18px;
                        }

                        .line {
                            font-size: 14px;
                            font-weight: 400;
                            color: #d3dcf5;
                            padding-bottom: 11px;
                        }
                    }

                    .question,
                    .number {
                        position: absolute;
                        color: #d3dcf5;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .question {
                        left: 21px;
                        top: 52px;
                    }

                    .number {
                        left: 780px;
                        bottom: 25px;
                    }
                }

                .picture {
                    width: 800px;
                    height: 400px;
                    // background-color: pink;
                    margin-top: 30px;
                }
            }
        }

        .bottom {
            margin-top: 6px;
            margin-bottom: 40px;
            width: 100%;
            background: #343c4f;
            border-radius: 6px;
            overflow: hidden;
            box-sizing: border-box;
            //  height: 1000px;
            .top {
                height: 50px;
                line-height: 50px;
                position: relative;

                .line {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 1px;
                    background-image: linear-gradient(to right, #535c77 0%, #535c77 50%, transparent 0%);
                    background-size: 10px 1px;
                    background-repeat: repeat-x;
                    width: 100%;

                }

                color: #ffffff;
                font-size: 16px;
                font-weight: 700;
                padding-left: 30px;
            }

            .block {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                padding: 0 58px 0 30px;
                box-sizing: border-box;

                .block-tit {
                    display: flex;
                    padding: 20px 0;

                    .cir {
                        width: 4px;
                        height: 4px;
                        background: #ffffff;
                        border-radius: 50%;
                        margin: 8px 4px 0 0;
                    }

                    .word {
                        color: #fff;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }

                .item {
                    padding: 15px 0;
                    background: #3d4557;
                    opacity: 1;
                    border-radius: 6px;
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 10px;
                    box-sizing: border-box;

                    .line-type {
                        color: #c3c8d5;
                        padding: 0 20px;
                    }

                    .line-top {
                        color: #c3c8d5;
                        padding: 14px 0 10px 20px;
                    }

                    .line-bottom {
                        color: #d3dcf5;
                        display: flex;
                        align-items: center;
                        span {
                            margin: 0 2px 0 10px;
                        }
                    }
                }
            }
        }

        .lines {
            height: 1px;
            width: 100%;
            background-color: transparent;
        }
    }
}
</style>
